import classNames from "classnames";
import { endOfMonth, format, sub } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { createScenario, updateScenario } from "../../../../api/scenario";
import {
  ESTIMATE_PROPERTY_DEFAULT_RATIO,
  loanTypes,
  scenarioTypes,
} from "../../../../commons/enum";
import LoadingSpinner from "../../../../components/loading-spinner";
import { FinanceContext } from "../../../../context/finance-context";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import {
  useIsInEmbedMode,
  useQueryInvalidationWithNotification,
} from "../../../../hooks";
import {
  useIsGettingFinance,
  useIsNotConnectingToAnyBank,
  useLastXMonthsIncomesExpensesSummary,
} from "../../../../hooks/account.hooks";
import { useFinanceSummary } from "../../../../hooks/common.hooks";
import {
  useIsScenarioOwner,
  useLvr,
  useScenarioList,
  useSortedScenarioList,
} from "../../../../hooks/scenario.hooks";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";
import { CloseIcon } from "../../../../images";
import { convertToInt } from "../../../../utils/convert-number";
import { isNotEmptyObject } from "../../../../utils/object-util";
import ScenarioFilterFinanceInput from "./scenario-filter-finance-input";
import ScenarioFilterItem from "./scenario-filter-item";
import ScenarioLoanToValueRatio from "./scenario-loan-to-value-ratio";
import ScenarioRefinance from "./scenario-refinance";
import ScenarioInivteGroup from "./scenario-invite-group";

const NUMBER_OF_LOOK_BACK_MONTHS = 2;
const NUMBER_OF_MONTH = 1;
const LAST_MONTH_RANGE = 1;

const getAverageIncomeAndExpenses = (incomeAndExpenseSummary, date) => {
  const {
    incomesByLastXMonths = {},
    connectiveExpensesByLastXMonths = {},
  } = incomeAndExpenseSummary;
  const { regular: regularIncome = [] } = incomesByLastXMonths?.[date] || {};
  const expensesByLastXMonths = connectiveExpensesByLastXMonths?.[date] || [];
  const totalRegularIncome = regularIncome?.reduce(
    (totalIncome, income) => totalIncome + income.avgAmount,
    0
  );
  const totalExpense = expensesByLastXMonths?.reduce(
    (totalExpenses, expense) =>
      totalExpenses +
      Math.abs(expense.amount) / (NUMBER_OF_LOOK_BACK_MONTHS + 1),
    0
  );
  return [totalRegularIncome, totalExpense];
};

const ScenarioFilter = ({ handleOpenScenario }) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const {
    scenarioFilterOpen,
    setDialogOpen,
    scenarioFilterData = {},
    setScenarioFilterData,
  } = useContext(ScenarioItemContext);
  const {
    selectedScenarioId,
    setSelectedScenarioId,
    selectedScenarioDetail,
  } = useContext(FinanceContext);
  const {
    data: incomeAndExpenseSummary = {},
  } = useLastXMonthsIncomesExpensesSummary({
    numberOfMonths: NUMBER_OF_MONTH,
    numberOfLookbackMonths: NUMBER_OF_LOOK_BACK_MONTHS,
  });
  const scenarioList = useSortedScenarioList();
  const editedScenario = scenarioList?.find((e) => e.id === selectedScenarioId);

  const lastMonth = format(
    endOfMonth(sub(new Date(), { months: LAST_MONTH_RANGE })),
    "yyyy-MM"
  );

  const [totalRegularIncome, totalExpense] = getAverageIncomeAndExpenses(
    incomeAndExpenseSummary,
    lastMonth
  );

  const isInEmbedMode = useIsInEmbedMode();
  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();
  const { data: userInfo = {} } = useMyInfo();
  const { firstName, lastName } = userInfo;
  const { isFetching: isFetchingScenarioList } = useScenarioList();
  const gettingFinance = useIsGettingFinance();
  const userSummary = useFinanceSummary();
  const cashDeposit = useMemo(() => {
    const { cashDeposit: destructureCashDeposit = 0 } = userSummary;
    return destructureCashDeposit;
  }, [userSummary]);

  const fullName = `${firstName} ${lastName}`;
  const [scenarioFilter, setScenarioFilter] = useState({});

  const [messageStatus, setMessageStatus] = useState("");
  const topAnchorRef = useRef(null);
  const { scenarioType } = scenarioFilter;
  const shouldShowMessage = !!messageStatus;
  const estimatePropertyDefault = useMemo(() => {
    return convertToInt(cashDeposit * ESTIMATE_PROPERTY_DEFAULT_RATIO);
  }, [cashDeposit]);
  const cashTowardProperty = useMemo(() => {
    return convertToInt(cashDeposit * 0.8);
  }, [cashDeposit]);
  const scrollToTop = () => {
    topAnchorRef.current?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    if (isNotConnectingToAnyBank) {
      setMessageStatus(
        "You must link at least one bank account to creata a new scenario"
      );
    } else if (gettingFinance) {
      setMessageStatus("Fetching finance data...");
    } else {
      setMessageStatus("");
    }
  }, [gettingFinance, isNotConnectingToAnyBank]);

  const handleCloseScenario = () => {
    setScenarioFilterData({});
    handleOpenScenario(false);
    setMessageStatus("");
  };

  const autoCloseScenario = () => {
    setTimeout(() => {
      handleCloseScenario();
    }, 1000);
  };

  const updateScenarioMutation = useMutation(
    (params) => updateScenario(selectedScenarioId, params, true),
    {
      onSuccess: () => {
        invalidateQueries("scenarioList").then(() => {
          setMessageStatus("Your scenario has been updated!");
          autoCloseScenario();
        });
        invalidateQueries(["scenarioProductList", selectedScenarioId]);
        invalidateQueries(["maxLoanHistoryChartData", selectedScenarioId]);
        invalidateQueries([
          "maxBuyingPowerHistoryChartData",
          selectedScenarioId,
        ]);
        invalidateQueries(["buyingPowerHistoryByScenario", selectedScenarioId]);
      },
      onError: () => {
        setMessageStatus("Failed to update scenario");
      },
    }
  );

  const createScenarioMutation = useMutation(createScenario, {
    onSuccess: (createdScenario) => {
      invalidateQueries("scenarioList").then(() => {
        setSelectedScenarioId(createdScenario.id);
        setMessageStatus("Scenario created successfully!");
        autoCloseScenario();
      });
    },
    onError: () => {
      setMessageStatus("Failed to creating scenario");
    },
  });

  const handleSubmitScenario = () => {
    setMessageStatus("");
    setDialogOpen(false);
    const data = {
      ...scenarioFilter,
      invitedEmails: scenarioFilter?.connections?.map((c) => c.email) || [],
    };
    delete data.connections;
    if (isNotEmptyObject(scenarioFilterData)) {
      updateScenarioMutation.mutate(data);
    } else {
      createScenarioMutation.mutate(data);
    }
  };

  const lvr = useLvr(estimatePropertyDefault, cashTowardProperty);

  useEffect(() => {
    if (scenarioFilterOpen) {
      if (isNotEmptyObject(scenarioFilterData)) {
        setScenarioFilter({
          ...scenarioFilterData,
          scenarioId: selectedScenarioId,
          connections:
            editedScenario?.jointScenarioConnections?.filter(
              (c) => c.memberId !== selectedScenarioDetail.mainUserId
            ) || [],
          applicant: editedScenario?.jointScenarioConnections?.length,
        });
      } else {
        setScenarioFilter({
          userName: `${process.env.SCENARIO_PREFIX} ${fullName}`,
          scenarioType: scenarioTypes.BUY_A_HOUSE,
          applicant: 1,
          dependants: 0,
          lvrSetting: {
            estimateProperty: estimatePropertyDefault,
            cashTowardProperty,
            monthlyRent: 0,
            estimateIncome: convertToInt(totalRegularIncome),
            estimateExpenses: Math.abs(convertToInt(totalExpense)),
            lvr,
          },
          propertyId: "",
          loanType: loanTypes.STANDARD_VARIABLE,
          propertyType: "Residential",
          features: [],
          connections: [],
          borrowers: [
            {
              id: userInfo.id,
              email: userInfo.email,
              estimateIncome: convertToInt(totalRegularIncome),
              estimateExpenses: Math.abs(convertToInt(totalExpense)),
            },
          ],
        });
      }
      scrollToTop();
    } else {
      setScenarioFilterData({});
    }
  }, [scenarioFilterOpen, cashDeposit, cashTowardProperty, editedScenario]);

  const handleFilterChange = (value) => {
    setMessageStatus("");
    setScenarioFilter((prevScenarioFilter) => ({
      ...prevScenarioFilter,
      ...value,
    }));
  };

  const isLoading =
    updateScenarioMutation.isLoading ||
    createScenarioMutation.isLoading ||
    isFetchingScenarioList;

  const isError =
    isNotConnectingToAnyBank ||
    gettingFinance ||
    updateScenarioMutation.isError ||
    createScenarioMutation.isError;

  const submitButtonText = isNotEmptyObject(scenarioFilterData)
    ? "Save Scenario"
    : "Create Scenario";
  const isOwner = useIsScenarioOwner();

  return (
    <div
      className={classNames("scenario-filter-component", {
        "scenario-out": !scenarioFilterOpen,
        "scenario-filter-component--embed": isInEmbedMode,
      })}
      tabIndex="-1"
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          handleCloseScenario();
        }
      }}
    >
      <div className="top-anchor" ref={topAnchorRef} />

      <ScenarioFilterItem
        title="What you want to do?"
        itemList={[
          { text: "Buy my first home", key: scenarioTypes.BUY_A_HOUSE },
          {
            text: "Buy an investment property",
            key: scenarioTypes.BUY_AN_INVESTMENT,
          },
          {
            text: "Refinance My Home Loan",
            key: scenarioTypes.REFINANCE_HOME_LOAN,
          },
        ]}
        filterType="scenarioType"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />
      {(isOwner || !scenarioFilter.scenarioId) && (
        <ScenarioFilterItem
          title="Applicants"
          itemList={[
            { text: "Just Me", key: 1 },
            { text: "Me + One", key: 2 },
            { text: "Me + Two", key: 3 },
          ]}
          filterType="applicant"
          setScenarioFilter={handleFilterChange}
          filterSelect={scenarioFilter}
        />
      )}

      <ScenarioInivteGroup
        filterSelect={scenarioFilter}
        setScenarioFilter={handleFilterChange}
        isOwner={isOwner || !scenarioFilter.scenarioId}
      />
      {scenarioType === scenarioTypes.REFINANCE_HOME_LOAN ? (
        <ScenarioRefinance
          setScenarioFilter={handleFilterChange}
          filterSelect={scenarioFilter}
          cashDeposit={cashDeposit}
        />
      ) : (
        <ScenarioLoanToValueRatio
          setScenarioFilter={handleFilterChange}
          filterSelect={scenarioFilter}
          cashDeposit={cashDeposit}
        />
      )}

      <ScenarioFilterFinanceInput
        title="Ingoing & Outgoing Estimates"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />

      <ScenarioFilterItem
        title="Dependants"
        itemList={[
          { text: "One little Me", key: 1 },
          { text: "Two Little Me’s", key: 2 },
          { text: "Three Little Me’s", key: 3 },
        ]}
        filterType="dependants"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />

      <ScenarioFilterItem
        title="Loan Type"
        itemList={[
          { text: "Standard Variable", key: loanTypes.STANDARD_VARIABLE },
          { text: "Fixed Rate", key: loanTypes.FIXED_RATE },
        ]}
        filterType="loanType"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />
      <ScenarioFilterItem
        title="Property Type"
        itemList={[
          { text: "Residential", key: "Residential" },
          { text: "Off The Plan", key: "Off the Plan Purchase" },
          { text: "Vacant Land - Metro", key: "Vacant Land - Metro" },
        ]}
        filterType="propertyType"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />
      <ScenarioFilterItem
        title="Features"
        itemList={[
          { text: "Extra Repayments", key: "Extra Repay" },
          { text: "Interest Only", key: "Interest Only" },
          { text: "100% Offset", key: "Offset" },
          { text: "Redraw Facility", key: "Redraw" },
        ]}
        filterType="features"
        setScenarioFilter={handleFilterChange}
        filterSelect={scenarioFilter}
      />
      <div className="scenario-filter__button-group">
        <button
          className="btn custom-button-fill"
          type="button"
          onClick={() => handleSubmitScenario()}
          onKeyDown={() => {}}
          tabIndex={0}
          disabled={gettingFinance || isLoading}
        >
          {isLoading ? <LoadingSpinner /> : submitButtonText}
        </button>
        {shouldShowMessage && (
          <span
            className={classNames("update-scenario-status text-color", {
              "text-danger": isError,
            })}
          >
            {messageStatus}
          </span>
        )}
      </div>

      <div
        className="close-button"
        role="button"
        onClick={() => handleCloseScenario()}
        onKeyDown={() => {}}
        tabIndex={0}
        aria-label="close-scenario-filter"
      >
        <img src={CloseIcon} alt="" />
      </div>
    </div>
  );
};

ScenarioFilter.propTypes = {
  handleOpenScenario: PropTypes.func,
};

export default ScenarioFilter;
