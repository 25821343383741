import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { primaryColor } from "../scss/colors.scss";
import { getFromToLastXDays as getFromToLastXDaysUtil } from "../utils/calendar-util";
import { dateComparator } from "../utils/chart-util";
import {
  getExpensesCategoryColor,
  getExpensesCategoryIcon,
} from "../utils/expensesUtils";
import { SORT_DIRECTION } from "./enum";

const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const cashBalanceHistoryChartBaseOptions = {
  scales: {
    xAxes: [
      {
        time: { unit: "day" },
        ticks: {
          maxTicksLimit: 7,
        },
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          maxTicksLimit: 5,
        },
      },
    ],
  },
};

export const cashBalanceHistoryChartOptionsMobile = {
  scales: {
    xAxes: [
      {},
      {
        position: "top",
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

export const overviewChartBaseOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  showLines: false,
  tooltips: {
    enabled: false,
  },
};

export const lineCustomOptionsOnMobile = {
  tension: 0.1,
};

export const getFromToLastXDays = (numberOfDays) => {
  return getFromToLastXDaysUtil(numberOfDays, DATE_FORMAT);
};

export const toChartDataPoint = ({ time, amount }) => ({
  x: format(new Date(time), DATE_FORMAT),
  y: amount,
});

export const toAbsoluteChartDataPoint = ({ time, amount }) => ({
  x: format(new Date(time), DATE_FORMAT),
  y: Math.abs(amount),
});

export const toPercentageBarDataPoint = (titleKey, total) => (item) => ({
  title: item[titleKey],
  percentage: total === 0 ? 0 : Math.abs(item.amount) / total,
});

export const toTopLivingExpenseCategoriesChartData = (categories) => ({
  data: categories.map((category) => Math.abs(category.amount)),
  title: categories.map((category) => category.category),
  colors: categories.map((category) =>
    getExpensesCategoryColor(category.category)
  ),
  icons: categories.map((category) =>
    getExpensesCategoryIcon(category.category)
  ),
});

const ascendingDateComparator = dateComparator(SORT_DIRECTION.ASCENDING);
const descendingDateComparator = dateComparator(SORT_DIRECTION.DESCENDING);

export const chartDataPointComparator = ({ x: date1 }, { x: date2 }) =>
  ascendingDateComparator(date1, date2);

export const transactionsComparator = (
  { transactionDate: date1 },
  { transactionDate: date2 }
) => descendingDateComparator(date1, date2);

export const toCashBalanceChartData = (cashBalanceHistory = []) => {
  const dataPoints =
    cashBalanceHistory.map(toChartDataPoint).sort(chartDataPointComparator) ||
    [];

  return dataPoints;
};

export const useMobileHeaderTabsStyles = makeStyles((theme) => ({
  indicator: {
    [theme.breakpoints.down("1025")]: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "80%",
        width: "100%",
        backgroundColor: primaryColor,
      },
    },
  },
}));

export const useSmallTabsStyles = makeStyles({
  tabsFlexContainer: {
    height: "100%",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: "60%",
      width: "100%",
      backgroundColor: primaryColor,
    },
  },
  tabs: {
    minHeight: "auto",
  },
  tab: {
    padding: 0,
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "none",
    minWidth: "100px",
    minHeight: "auto",
  },
  selectedTab: {
    color: "#000000",
  },
});
